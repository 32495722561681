@keyframes loadingAnimation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.loading-color-finished,
.loading-color-planned {
  height: 100%;
  width: 0;
}

.animate {
  animation-duration: 4s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-name: loadingAnimation;
}
