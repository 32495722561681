@import-normalize;
@font-face {
  font-family: 'Lexend Exa HM';
  src: url("../public/fonts/LexendExaHM-Xlight.ttf") format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Lexend HM';
  src: url("../public/fonts/LexendHM-Bold.ttf") format('truetype');
  font-style: bold;
  font-weight: 700;
}
@font-face {
  font-family: 'Lexend Exa HM';
  src: url("../public/fonts/LexendExaHM-Bold.ttf") format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Lexend HM';
  src: url("../public/fonts/LexendHM-Regular.ttf") format('truetype');
  font-style: normal;
  font-weight: 400;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .leaflet-control-attribution.leaflet-control {
  display: none;
} */

.leaflet-left {
  display: none;
}
