
.toolbarContainer svg >path{
  /* color: white; */
  fill: white;
  /* transform: scale(.42);
  margin-left: 8%; */
}

.markedNavElement > a > p{
  outline: 2px solid;
  border-radius: 26px;
  color: #D9E868;
  -webkit-box-pack: center;
}
.markedNavElement > a{
  text-decoration: none;
}
.markedNavElement:hover > a > p{
  color: #FFFFFF;
}
.menuContainer button  >svg {
  height: 32px;
  width: 32px;

}

.headerMobileViewHamburger { 
  display: contents;
}


.hoverLine {
  position: relative;
  color: #D9E868;
  text-decoration: none;
}

.hoverLine:hover {
  color: #D9E868;
}
.hoverLine:hover p {
  color: #D9E868;
}
.hoverLine::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #D9E868;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.2s ease;
  text-decoration: none;
}

.hoverLine:hover::before {
  transform: scaleX(1);
  text-decoration: none;
}



.accordionMenu {
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  top: 0px;
  left: 0px;
  z-index: 9001;
  width: 100%;
  background-color: rgba(0, 88, 81, 0.5);
  transition: height 1s;
}
.accordionMenuList {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #005851;
  padding-bottom: 32px;
  padding-top: 80px;
  padding-left: 44px;

}
.navbarFixedPadding{
  padding-top: 88px;
}

@media (min-width: 600px) {  
  .accordionMenuList {
    padding-top: 88px;
    padding-left: 44px;
    gap: 18px;
  }
  
}

@media (min-width: 900px) {  
  .accordionMenuList {
    padding-top: 112px;
    padding-left: 66px;
    gap: 22px;
  }
}

@media (min-width: 1201px) {  
  .accordionMenu {
    display: none;
  }
}

@media (max-width: 900px) {  
  .navbarFixedPadding{
    padding-top: 64px;
  }
}
@media (max-width: 600px) {  
  .navbarFixedPadding{
    padding-top: 56px;
  }
}